import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import Container from "../../components/Layout/Container";
// import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";

import Seo from "../../components/reusable/Seo";

const CaseStudy1 = () => {
  return (
    <Page className="case-study">
      <Seo
        title="Case Study : Intelligent Product Discovery Platform "
        path="case-studies/intelligent-product-discovery-platform"
        description="Built an Intelligent Product Discovery Platform for one of the leading companies in digital and physical retail in Latin America"
      />
      <section className="hero">
        <Container>
          <div className="text">
            <div className="h1-prefix">
              <span>Case Study</span>
            </div>
            <h1>
              <b>
                Intelligent Product Discovery Platform to drive conversation
                rate and improve shopping experience{" "}
              </b>
            </h1>
          </div>
          <figure>
            <StaticImage
              src="../../images/casestudy/product-discovery-platform.jpg"
              alt="Intelligent Product Discovery Platform to drive conversation
              rate and improve shopping experience"
              layout="fullWidth"
              loading="eager"
              placeholder="blurred"
            />
          </figure>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              Built an Intelligent Product Discovery Platform for one of the
              leading companies in digital and physical retail in Latin America
            </b>
          </p>
          <p>
            Client is present in 7 countries and offers multiple ecommerce
            services to 30 million customers. Client wanted to replace existing
            on-prem product discovery solution with a cloud-native platform to
            support their rapid growth and provide improved shopping experience.
          </p>
          <br />
          <div className="keypoints">
            <ul>
              <li>
                <span>More than 7 million products from 10,000 sellers  </span>
              </li>
              <li>
                <span>USD$ 3.3bn GMV &amp; 123% yoy growth</span>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="biz-value">
        <Container>
          <h2>
            <b>Business Value Delivered</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <strong>12%</strong> <span>increase in conversion rate</span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>8%</strong> <span>increase in average bucket size</span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>$48M</strong> <span>increase in project revenue</span>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="challenges">
        <Container>
          <h2>
            <b>Business Challenges </b>
          </h2>
          <ul>
            <li>
              <span>
                Existing solution was hosted in private data centers as a single
                monolith application.
              </span>
            </li>
            <li>
              <span>Slow Feature release cycle.</span>
            </li>
            <li>
              <span>Stale Data. High use of manual rules.</span>
            </li>
          </ul>
        </Container>
      </section>
      <section className="solution">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <ul>
            <li>
              <span>
                Migrated existing solution to Google Cloud and upgraded it to be
                scalable, provide high availability and capabilities to build
                intelligence on top of the existing platform .
              </span>
            </li>
            <li>
              <span>
                Built data pipeline for user behavior to find patterns, and
                understand users to provide a modern, personalized and
                frictionless customer experience. 
              </span>
            </li>
            <li>
              <span>
                Provided Multi-Tenant Architecture to enable all the companies
                in the group to use the same platform.{" "}
              </span>
            </li>
            <li>
              <span>
                Implemented self-learning search engine to continuously improve
                the product discovery platform based on how end-users are using
                the platform. 
              </span>
            </li>
          </ul>
        </Container>
      </section>
      <ContactBlock />
    </Page>
  );
};

export default CaseStudy1;
